/*
 * sidebar.js
 */

jQuery(document).ready(function () {

    $('.sidebar__mobile__openGarancies').click(function () {
        if (!$(this).hasClass('sidebar__mobile__openGarancies--active')) {
            $('.sidebar__mobile__openGarancies').addClass('sidebar__mobile__openGarancies--active');
            $('.sidebar__mobile__garancies').addClass('sidebar__mobile__garancies--active');
        } else {
            $('.sidebar__mobile__garancies').removeClass('sidebar__mobile__garancies--active');
            $('.sidebar__mobile__openGarancies').removeClass('sidebar__mobile__openGarancies--active');
        }
    });
    $('.sidebar__mobile__garancies__close').click(function () {
        $('.sidebar__mobile__garancies').removeClass('sidebar__mobile__garancies--active');
        $('.sidebar__mobile__openGarancies').removeClass('sidebar__mobile__openGarancies--active');
    });


    $('#pagamento_info').tooltipster({
        animation: 'grow',
        delay: 400,
        //minWidth: 300,
        maxWidth: 350,
        theme: 'tooltipster-shadow',
        zIndex: 9999,
        side: 'right',
        trigger: 'custom',
        triggerOpen: {
            mouseenter: true,
            click: true,
            touchstart: true
        },
        triggerClose: {
            mouseleave: true,
            click: true,
            scroll: true,
            tap: true
        },
        interactive: true
    });

    /*
    $('#appointment').click(function () {
        $.scrollTo('.mappa__form', 400);
        var $this = $(this);
        if ($this.hasClass('cta--red'))
            $this.removeClass('cta--red').addClass('cta--green').addClass('cta--disabled').children('span').text('Conferma appuntamento')
    });
    $('#quotation').click(function () {
        $.scrollTo('.quotazione', 400);
        var $this = $(this);
        if ($this.hasClass('cta--red'))
            $this.removeClass('cta--red').addClass('cta--red').addClass('cta--disabled').children('span').text('Invia appuntamento')
    });
    $('#appointment-mob').click(function () {
     $.scrollTo('.mappa__form', 400);
     });
     $('#quotation-mob').click(function () {
     $.scrollTo('.quotazione', 400);
     });
     */
});