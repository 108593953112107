/**
 *  JS: resources/views/assets/js/helpBox.js
 *
 */

$(document).ready(function () {
    var $helpBox = $("#helpBox");
    var $footer = $("footer.footer");

    function removeSticky() {
        var footerH = $footer.outerHeight();
        $helpBox
            .removeClass('sticky')
            .addClass('at-bottom')
            .css({
                bottom: footerH
            });

    }

    function addSticky() {
        $helpBox
            .removeClass('at-bottom')
            .addClass('sticky')
            .css({
                bottom: 0
            });
    }

    function initHelp() {
        if ($footer.visible(true, true, 'both')) {
            removeSticky();
        } else {
            addSticky();
        }
    }
    if ($helpBox.length) {
        initHelp();
        $(window)
            .resize(function () {
                initHelp()
            })
            .scroll(function () {
                initHelp()
            });
    }
});