/**
 *  JS: resources/views/assets/js/cookies.js
 *
 */

$(document).ready(function(){
    // Definizione variabili
    const cookieName = 'cookie_bar';
    const cookie_expiring = 7;
    const cookiePath = '';
    //const cookies_expiring_minute = 2;

    var getCookie = Cookies.get(cookieName);
    if (empty(getCookie) ) {
        setTimeout(function(){
            var $cookiesBar = $('#cookiesBar');
            var $cookieButton = $('#cookieButton');
            $('main').prepend($cookiesBar);
            $cookiesBar.collapse('show');
            $cookieButton.click(function(){
                $cookiesBar.collapse('hide');
                //var cookie_expiring_date = new Date(new Date().getTime() + cookies_expiring_minute * 60 * 1000);
                Cookies.set(cookieName, '1', { expires: cookie_expiring, path: cookiePath });
            });
        },600);
    }
});